<template>
  <div class="p-4">
    <h4 class="mb-2">Submit A Ticket</h4>
    <b-container bg-variant="success" class="px-5 d-flex justify-content-center">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Requester"
              label-for="h-first-Requester"
              label-cols-md="4"
            >
              <b-form-input id="h-Requester" type="Email" placeholder="Email" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Subject" label-for="h-Subject" label-cols-md="4">
              <b-form-input id="h-Subject" type="text" placeholder="Subject" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Product / Service"
              label-for="h-Product"
              label-cols-md="4"
            >
              <b-form-select v-model="selected" :options="product" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Priority" label-for="h-Priority" label-cols-md="4">
              <b-form-select v-model="selected" :options="priority" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Description" label-for="h-Description" label-cols-md="4">
              <quill-editor v-model="content" :options="snowOption" />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BAlert,
    BLink,
    quillEditor,
    BFormSelect,
    BRow,
    BContainer,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      product: [
        { value: null, text: "Product 1" },
        { value: "a", text: "Product 2" },
        { value: "b", text: "Product 3" },
        { value: { C: "3PO" }, text: "Product 4" },
        { value: "d", text: "Product 5" },
      ],
      priority: [
        { value: null, text: "Low" },
        { value: "a", text: "Medium" },
        { value: "b", text: "High" },
      ],
      content: ``,
    };
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
};
</script>
